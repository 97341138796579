export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "Choose Your Contest",
    description:
      "Select from a variety of cricket contests with different entry fees and prize pools.",
  },
  {
    icon: "tradingItemTwo",
    title: "Create Your Team",
    description:
      "Use your cricket knowledge to select the best players for your fantasy team.",
  },
  {
    icon: "tradingItemThree",
    title: "Follow the Action",
    description:
      "Watch the real match and track your fantasy team's performance in real-time.",
  },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    icon: "tradingItemOne",
    title: "Unique Gameplay",
    description:
      "Our platform offers innovative gameplay modes, enhancing your fantasy cricket experience with diverse challenges and strategies.",
  },
  {
    icon: "tradingItemTwo",
    title: "Community Engagement",
    description:
      "Join a vibrant community of cricket enthusiasts, share insights, and compete in leagues to test your skills against others.",
  },
  {
    icon: "tradingItemThree",
    title: "Fair Play Assurance",
    description:
      "We ensure fair play by implementing strict anti-cheating measures and regular audits, providing a level playing field for all users.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is Thezonezap?",
    content:
      "Thezonezap is a fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with Thezonezap?",
    content:
      "Download the Thezonezap app from the Download App Button and create an account, then join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage of the app, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "I forgot my password. How can I reset it?",
    content: `Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.`,
  },
  {
    id: "flush-headingFive",
    title: "How do I join a league?",
    content: `After creating an account, you can search for public leagues or enter a league code if you have one to join a private league.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];

export const clientReview = [
  {
    icon: "startPlay2",
    title: "Dairy & Bakery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Grocery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Ayurvedic",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
];
